import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ContactForm from "../components/Layout/ContactForm";

const ContactPage = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Leo6vQaAAAAAL40XKSOwT1k575J-RODfOYLfDZK">
      <ContactForm />
    </GoogleReCaptchaProvider>
  );
};

export default ContactPage;
