import { Button } from "@material-ui/core";
import { useHistory } from "react-router";

import classes from "./NotFoundPage.module.css";

const NotFoundPage = () => {
  const history = useHistory();

  const handleRouteHome = () => {
    history.replace("/");
  };

  return (
    <div className={classes["notFoundPage"]}>
      <h1>Page Not Found</h1>
      <p className={classes["zoom-area"]}>
        Ooops! Page you are looking for is not yet created &#128556;
      </p>
      <section className={classes["error-container"]}>
        <span>4</span>
        <span>
          <span className={classes["screen-reader-text"]}>0</span>
        </span>
        <span>4</span>
      </section>
      <div className={classes["link-container"]}>
        <Button
          variant="contained"
          disableElevation
          onClick={handleRouteHome}
          color="default"
          className={classes.button}
        >
          Home Page
        </Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
