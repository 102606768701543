import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Typography, CardContent } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    paddingLeft: theme.spacing(1),
  },
  card: {
    padding: theme.spacing(1),
  },
}));

const CardSection = (props) => {
  const classes = useStyles();
  return (
    <Card raised={true} className={classes.card}>
      <CardContent>
        <div className={classes.titleContainer}>
          <FontAwesomeIcon icon={props.icon} size="2x" />
          <Typography variant="h4" className={classes.title}>
            {props.title}
          </Typography>
        </div>
      </CardContent>
      <CardContent>{props.children}</CardContent>
    </Card>
  );
};

export default CardSection;
