import { Redirect, Route, Switch } from "react-router-dom";

import Layout from "./components/Layout/Layout";
import AboutPage from "./pages/AboutPage";
import BlogPage from "./pages/BlogPage";
import ContactPage from "./pages/ContactPage";
import NotFoundPage from "./pages/NotFoundPage";
// import ProjectsPage from "./pages/ProjectsPage";

import "./App.css";

function App() {
  return (
    <Layout>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/about" />
        </Route>
        <Route path="/about">
          <AboutPage />
        </Route>
        {/* <Route path="/projects">
          <ProjectsPage />
        </Route> */}
        <Route path="/contact">
          <ContactPage />
        </Route>
        <Route path="/blog">
          <BlogPage />
        </Route>
        <Route path="/notFound">
          <NotFoundPage />
        </Route>
        <Route path="*">
          <Redirect to="/notFound" />
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;
