import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  separator: {
    border: "0",
    margin: "20px 0",
    borderTop: "1px solid #eee",
  },
}));

const LineSeparator = () => {
  const classes = useStyles();
  return <hr className={classes.separator} />;
};

export default LineSeparator;
