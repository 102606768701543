import { useCallback, useEffect, useReducer, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  Container,
  Card,
  TextField,
  CardContent,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";

import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { sendMail } from "../../api/index";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  contactForm: {
    width: isMobile ? "100%" : "50%",
    minWidth: isMobile ? "100%" : "495px",
    padding: theme.spacing(3),
  },
  formElement: {
    marginTop: theme.spacing(2),
  },
  buttonRow: {
    display: "flex",
    justifyContent: "right",
    padding: 0,
    marginTop: theme.spacing(2),
  },
  cardHeader: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    width: "75%",
    padding: theme.spacing(2),
    color: "white",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "4px",
    marginBottom: theme.spacing(2),
  },
  cardHeaderText: {
    fontWeight: "800",
    paddingLeft: theme.spacing(2),
  },
  loadingWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const checkFieldLongEnough = (value, len) => {
  if (!value) {
    return false;
  }
  return value.trim().length >= len;
};

const checkEmailIsValid = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const initialState = {
  email: "",
  emailIsValid: false,
  emailIsTouched: false,
  name: "",
  nameIsValid: false,
  nameIsTouched: false,
  subject: "",
  subjectIsValid: false,
  subjectIsTouched: false,
  message: "",
  messageIsValid: false,
  messageIsTouched: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "email":
      return {
        ...state,
        email: action.value,
        emailIsTouched: true,
        emailIsValid: checkEmailIsValid(action.value),
      };
    case "name":
      return {
        ...state,
        name: action.value,
        nameIsTouched: true,
        nameIsValid: checkFieldLongEnough(action.value, 5),
      };
    case "subject":
      return {
        ...state,
        subject: action.value,
        subjectIsTouched: true,
        subjectIsValid: checkFieldLongEnough(action.value, 10),
      };
    case "message":
      return {
        ...state,
        message: action.value,
        messageIsTouched: true,
        messageIsValid: checkFieldLongEnough(action.value, 20),
      };
    case "init":
      return initialState;
    default:
      return state;
  }
};

const initialToast = {
  show: false,
  type: "",
  message: "",
};

const ContactForm = () => {
  const classes = useStyles();
  const [formState, dispatch] = useReducer(reducer, initialState);
  const [token, setToken] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState(initialToast);

  const formIsValid =
    formState.emailIsValid &&
    formState.nameIsValid &&
    formState.subjectIsValid &&
    formState.messageIsValid &&
    token;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formIsValid) {
      setLoading(true);

      const mailOptions = {
        fromEmail: formState.email,
        fromName: formState.name,
        emailSubject: formState.subject,
        emailBodyText: formState.message,
        emailBodyHtml: `<!DOCTYPE html>
        <html>
        <head>
        <title>Page Title</title>
        <link rel="preconnect" href="https://fonts.gstatic.com">
        <link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet">
        <style>
        
        .bdBody, .bdBody *{
          font-family:"Roboto", "Arial", sans-serif;
        }
        .bdBody{
          display:flex; flex-direction:column; align-items:center; width:100%;
        }
        
        .bdMainContainer{
          background-color:#2b2f33; 
            width:70%; 
            min-width:400px; 
            max-width:600px; 
            padding:15px;
            border-radius:4px; 
            min-height:600px; 
            margin:15px;
            
        }
        
        .bdMainHeader{
          margin: 0 auto;
            text-align: center;
            background-color: #5770d2;
            padding: 10px;
            width: 70%;
            border-radius: 4px;
            color: whitesmoke;
        }
        
        .bdBodyContainer{
          box-sizing:border-box;
            display: block;
            padding: 3px;
          margin-top:30px;
            width:100%;
        }
        
        .bdBodyContainerCell{
            padding: 5px 10px;
            border-radius:2px;
            line-height: 2rem;
            margin-bottom:5px;
            
        }
        .bdBodyContainerCell a{
            color: whitesmoke;
        }
        
        .bdBodyContainerLabel{
        background-color: #797575;
        font-weight: bold;
        color: whitesmoke;
        }
        
        .bdBodyContainerValue{
            background-color: #5770d2;
            color: whitesmoke;  
        }
        
        .bdBodyContainerLong{
          height:300px;
        }
        </style>
        </head>
        <body class="bdBody">
        
        <div class="bdMainContainer">
          <h2 class="bdMainHeader">New Message From Your Site</h2>
          <div class="bdBodyContainer">
              
                  <div class="bdBodyContainerCell bdBodyContainerLabel">From </div>
                  <div class="bdBodyContainerCell bdBodyContainerValue"><a href="mailto:${formState.email}">${formState.name} - ${formState.email}</a> </div>
                  <div class="bdBodyContainerCell bdBodyContainerLabel">Subject </div>
                  <div class="bdBodyContainerCell bdBodyContainerValue">${formState.subject}</div>
        
                  <div class="bdBodyContainerCell bdBodyContainerLabel">Message </div>
                  <div class="bdBodyContainerCell bdBodyContainerValue bdBodyContainerLong">${formState.message}</div>
            </div>
        </div>
        
        </body>
        </html>        
        `,
      };

      try {
        const mailResponse = await sendMail(mailOptions);
        if (mailResponse.success) {
          setToastMessage((state) => {
            return {
              show: true,
              type: "success",
              message: mailResponse.message,
            };
          });

          dispatch({ type: "init" });
        } else {
          setToastMessage((state) => {
            return {
              show: true,
              type: "error",
              message: mailResponse.message,
            };
          });
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const getCaptchaToken = useCallback(async () => {
    try {
      const captchaToken = await executeRecaptcha("ContactPage");

      setToken(captchaToken);
    } catch (e) {
      setToken(null);
    }
  }, [executeRecaptcha]);

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }
    getCaptchaToken();
  }, [executeRecaptcha, getCaptchaToken]);

  const handleChange = (field, event) => {
    dispatch({ type: field, value: event.target.value });
  };

  const handleCloseToast = () => {
    setToastMessage(() => initialToast);
  };

  return (
    <Container className={classes.formContainer} fluid="true">
      <Card raised={true} className={classes.contactForm}>
        <div className={classes.cardHeader}>
          <FontAwesomeIcon icon={faEnvelope} size="2x" />
          <Typography
            variant="h5"
            component="span"
            className={classes.cardHeaderText}
          >
            Contact Me
          </Typography>
        </div>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <TextField
              required
              id="email"
              fullWidth
              error={formState.emailIsTouched && !formState.emailIsValid}
              className={classes.formElement}
              helperText={
                formState.emailIsTouched &&
                !formState.emailIsValid &&
                `Please enter a valid email`
              }
              label="Email"
              type="email"
              value={formState.email}
              onChange={handleChange.bind(null, "email")}
              placeholder="Please enter your email address"
            />
            <TextField
              required
              id="fullname"
              fullWidth
              className={classes.formElement}
              label="Your Name"
              type="text"
              value={formState.name}
              error={formState.nameIsTouched && !formState.nameIsValid}
              helperText={
                formState.nameIsTouched &&
                !formState.nameIsValid &&
                `Please enter your name (Min 5 characters)`
              }
              onChange={handleChange.bind(null, "name")}
              placeholder="Please provide your name and surname"
            />
            <TextField
              required
              id="subject"
              fullWidth
              className={classes.formElement}
              label="Subject"
              type="text"
              value={formState.subject}
              error={formState.subjectIsTouched && !formState.subjectIsValid}
              helperText={
                formState.subjectIsTouched &&
                !formState.subjectIsValid &&
                `Please enter a valid subject (Min 10 characters)`
              }
              onChange={handleChange.bind(null, "subject")}
              placeholder="Please enter the subject of the email"
            />
            <TextField
              required
              className={classes.formElement}
              fullWidth
              id="message"
              multiline
              label="Message"
              rows={5}
              value={formState.message}
              error={formState.messageIsTouched && !formState.messageIsValid}
              helperText={
                formState.messageIsTouched &&
                !formState.messageIsValid &&
                `Please enter a valid message (Min 20 characters)`
              }
              onChange={handleChange.bind(null, "message")}
              placeholder="Please enter an email content"
            />
            {/* <GoogleReCaptcha onVerify={handleCaptchaVerify} /> */}
            <Container className={classes.buttonRow}>
              <div className={classes.loadingWrapper}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!formIsValid || loading}
                >
                  Send Message
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Container>
          </form>
        </CardContent>
      </Card>
      <Snackbar
        open={toastMessage.show}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseToast} severity={toastMessage.type}>
          {toastMessage.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ContactForm;
