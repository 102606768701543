import { useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";

import Footer from "./Footer";
import Navigation from "./Navigation";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    marginTop: "64px",
    marginBottom: "1rem",
    padding: isMobile ? theme.spacing(1) : theme.spacing(3),
  },
}));

const Layout = (props) => {
  const location = useLocation();
  const notFound = location.pathname === "/notFound" ? true : false;

  const classes = useStyles();

  if (notFound) {
    return <div className={classes["root"]}>{props.children}</div>;
  }

  return (
    <div className={classes["root"]}>
      <Navigation />
      <main className={classes["main"]}>
        <section>{props.children}</section>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
