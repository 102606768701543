const axios = require("axios");

const MAIL_SERVICE_URL = "/api/sendMail";

export const sendMail = async (mailOptions) => {
  const req = {
    ...mailOptions,
    sendMethod: "HTML",
    customId: "BAYRAMDABAK.COM-CONTACT PAGE",
  };

  try {
    const res = await axios.post(MAIL_SERVICE_URL, {
      data: req,
      headers: {
        "Content-Type": "application/json",
      },
    });

    return {
      success: true,
      message:
        "Thanks for reaching out! Now it is my turn to write a response :)",
      response: res,
    };
  } catch (err) {
    return {
      success: false,
      message: "Ooops! There seems to be a problem with mail service!",
      response: err,
    };
  }
};
