import React from "react";
import { NavLink, Link } from "react-router-dom";
import Logo from "../../assets/logo.svg";
import { AppBar, Toolbar, Menu, MenuItem, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import MoreIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  header: {
    backgroundColor: "#ececec",
    color: "#283546",
    fontFamily: "Fira Sans, sans-serif",
  },
  nav: {
    display: "block",
    "& ul": {
      listStyle: "none",
      margin: "0",
      padding: "0",
      display: "flex",
      alignItems: "center",
    },
    "& li": {
      margin: "0 1rem",
    },
    "& a": {
      color: "rgba(0, 0, 0, 0.54) !important",
      textDecoration: "none !important",
      fontWeight: "300 !important",
    },

    "& a:hover, & a.active": {
      textDecoration: "none !important",
      color: "#007aac !important",
      paddingBottom: "0.25rem",
      borderBottom: "2px solid #007aac",
    },
  },
  mobileNavMenu: {
    "& a": {
      textDecoration: "none !important",
      color: "#007aac",
    },
    "& li": {
      transition: "all 0.5s ease-in-out",
      backgroundColor: "transparent",
    },
    "& li:hover": {
      textDecoration: "none !important",
      backgroundColor: "#ececec",
    },
  },
}));

const Navigation = () => {
  const classes = useStyles();
  let navMenu;
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const mobileMenuId = "menu-mobile";
  const renderMobileMenu = (
    <Menu
      className={classes["mobileNavMenu"]}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Link to="/about">About</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/contact">Contact</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/blog">Blog</Link>
      </MenuItem>
    </Menu>
  );
  if (!isMobile) {
    navMenu = (
      <div className={classes.sectionDesktop}>
        <nav className={classes.nav}>
          <ul>
            <li>
              <NavLink activeClassName={classes["active"]} to="/about">
                About
              </NavLink>
            </li>
            {/* <li>
              <NavLink activeClassName={classes["active"]} to="/projects">
                Projects
              </NavLink>
            </li> */}
            <li>
              <NavLink activeClassName={classes["active"]} to="/contact">
                Contact
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName={classes["active"]} to="/blog">
                Blog
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    );
  } else {
    navMenu = (
      <div>
        <IconButton
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
        >
          <MoreIcon />
        </IconButton>
      </div>
    );
  }
  return (
    <AppBar className={classes.header}>
      <Toolbar>
        <Link to="/">
          <img src={Logo} alt="Brand Logo" />
        </Link>
        <div className={classes.grow} />
        {navMenu}
        {isMobile && renderMobileMenu}
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
