import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
// import classes from "./Footer.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const useStyles = makeStyles((theme) => ({
  footer: {
    height: "6rem",
    marginTop: "auto",
    backgroundColor: theme.palette.grey[700],
  },
  footerContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: theme.spacing(2),
  },
  footerLinkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "150px",
    paddingTop: theme.spacing(2),
    "& a": {
      textDecoration: "none",
      color: "white",
    },
    "& a:visited, & a:hover": {
      textDecoration: "none",
    },
  },
  footerTypo: {
    color: "white",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <div className={classes.footerContent}>
        <Typography component="p" className={classes.footerTypo}>
          Find Me on Social Media &#128521;
        </Typography>
        <div className={classes.footerLinkContainer}>
          <a
            href="https://www.linkedin.com/in/bayram-dabak-b04b8943/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} size="lg" />
          </a>
          <a
            href="https://www.facebook.com/bayram.dabak"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} size="lg" />
          </a>

          <a
            href="https://twitter.com/BayramDabak"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} size="lg" />
          </a>
          <a
            href="https://www.instagram.com/bayram.dabak/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
