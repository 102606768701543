import HistoricalItem from "../UI/HistoricalItem";
import CardSection from "../UI/CardSection";

import { faSuitcase } from "@fortawesome/free-solid-svg-icons";

const WORK_EXPERIENCE_DATA = [
  {
    id: 1,
    title: "Founding Partner, SAP Solution Architect / SMOD Business Solutions",
    description: `I decided to start my own business in 2013. I joined to SMOD Business Solutions as Founding Partner. 
    Here, as a team, we started to accomplish things that we could not have dreamed of before. 
    We have not only implemented classical SAP ERP projects, but also carried out works that will add value to SAP systems.
    We separated our teams to be ABAP, FIORI, support and project teams. I personally took part in the training of the teams.
    We have developed programs and applications that significantly improve the SAP usage experience using 
    FIORI, NodeJS, Golang programming languages. Some of the developments I mentioned that made the difference are SFTP Bank Payment File Integration, SAP-Active Directory Integration, 
    Kariyer.Net - SAP Applicant data integration, FIORI Performance Appraisal Forms, FIORI Balance Scorecard, Payslip Service (Golang), 
    Notifications Service (NodeJS, Golang), Kayıtlı Elektronik Posta (KEP) Service (All kind of employee documents,notifications can be sent with the digital signature of the sender and the documents that are sent via KEP are accepted as legal proof) 
    `,
    beginDate: "2013",
    endDate: null,
  },
  {
    id: 2,
    title: "SAP HCM&ABAP Consultant / Infra-Logics Consulting",
    description: `I started working in Infra-Logics Consulting at early 2010 as SAP HCM Senior Consultant. 
    Infra-Logics was a great company where I took part in projects that left a big mark in my career and development and contributed greatly 
    to my development.
    At that time, SAP Enterprise Portal projects (ESS and MSS), BSP and Webdynpro ABAP technologies were so popular.  
    Therefore while I was improving myself on Webdynpro ABAP and BSP programming languages, I participated the real projects and in which had chances to practice these technologies. 
    During my career here, I had the chance to add new modules to my previous knowledge. I added new know-how such as HRFORMS, Travel Management, 
    Workflows, Training Management, Talent Management, SAP Portal, SAP Integration methods.`,
    beginDate: "2010",
    endDate: "2013",
  },
  {
    id: 3,
    title: "SAP HCM&ABAP Consultant / ISC Consulting",
    description: `I started as an SAP HCM consultant to my career. ISC was a second school for me. I have acquired the basis of all information 
    about Human Resources here, that's why ISC in me is so special.  
    After I learned about the fundamentals of SAP, HCM processes and modules, I completed ABAP/4 course and took my certificate. 
    Shortly after I began developing ABAP, I started to take part in both technical and functional roles in projects.
    I attended more than 10 local and global projects in which I was given the responsibility of all kind of possible 
    project phases such as analysis, design, development and testing.
    During my career here, I mostly focused on improving myself on Personnel Management, Organizational Management, Performance Management, 
    Payroll, Time Management, Employee Self Service (ESS) and Manager Self Service (MSS) modules.`,
    beginDate: "2005",
    endDate: "2010",
  },
];

const WorkExperiences = () => {
  return (
    <CardSection icon={faSuitcase} title={"Work Experience"}>
      {WORK_EXPERIENCE_DATA.map((exp) => {
        return (
          <HistoricalItem
            key={exp.id}
            title={exp.title}
            description={exp.description}
            beginDate={exp.beginDate}
            endDate={exp.endDate}
          />
        );
      })}
    </CardSection>
  );
};

export default WorkExperiences;
