import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme();

theme.typography.h1 = {
  fontSize: "4.5rem",
  "@media (min-width:600px)": {
    fontSize: "6rem",
  },
};

theme.typography.h2 = {
  fontSize: "2.82rem",
  "@media (min-width:600px)": {
    fontSize: "3.75rem",
  },
};

theme.typography.h3 = {
  fontSize: "2.25rem",
  "@media (min-width:600px)": {
    fontSize: "3rem",
  },
};

theme.typography.h4 = {
  fontSize: "1.60rem",
  "@media (min-width:600px)": {
    fontSize: "2.125rem",
  },
};

theme.typography.h5 = {
  fontSize: "1.125rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
};

theme.typography.h6 = {
  fontSize: "0.9375rem",
  "@media (min-width:600px)": {
    fontSize: "1.25rem",
  },
};

theme.typography.body1 = {
  fontSize: "0.75rem",
  "@media (min-width:600px)": {
    fontSize: "1rem",
  },
};

theme.typography.body2 = {
  fontSize: "0.66rem",
  "@media (min-width:600px)": {
    fontSize: "0.875rem",
  },
};

theme.typography.subtitle1 = {
  fontSize: "0.75rem",
  "@media (min-width:600px)": {
    fontSize: "1rem",
  },
};

theme.typography.subtitle2 = {
  fontSize: "0.66rem",
  "@media (min-width:600px)": {
    fontSize: "0.875rem",
  },
};

const AppTheme = (props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default AppTheme;
