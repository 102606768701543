import { useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import ProfileCard from "../components/Layout/ProfileCard";
import ProfileDetail from "../components/Layout/ProfileDetail";
import Slide from "@material-ui/core/Slide";
import Grow from "@material-ui/core/Grow";

const AboutPage = () => {
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    const animateFn = setTimeout(() => {
      setShowAnimation(true);
    }, 100);

    return () => {
      clearTimeout(animateFn);
    };
  }, []);

  return (
    <Grid container spacing={3} justify="center">
      <Grow in={showAnimation}>
        <Grid item sm={12} md={4}>
          <ProfileCard />
        </Grid>
      </Grow>
      <Grow in={showAnimation} direction="left">
        <Grid item sm={12} md={8}>
          <ProfileDetail />
        </Grid>
      </Grow>
    </Grid>
  );
};

export default AboutPage;
