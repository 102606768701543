import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Container, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import LineSeparator from "./LineSeparator";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  title: {
    paddingLeft: theme.spacing(1),
  },
  card: {
    padding: theme.spacing(3),
  },
  currentBadge: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: "3px",
    padding: "3px",
    paddingLeft: "7px",
    paddingRight: "7px",
  },
  padlessElement: {
    padding: 0,
    margin: 0,
  },
}));

const HistoricalItem = (props) => {
  const classes = useStyles();
  return (
    <Container xs={12} className={classes.padlessElement}>
      <Typography variant="h5" color="textSecondary">
        {props.title}
      </Typography>
      <div className={classes.titleContainer}>
        <FontAwesomeIcon icon={faCalendarAlt} size="lg" />
        <Typography variant="h6" className={classes.title}>
          {props.beginDate} -{" "}
          {props.endDate ? (
            props.endDate
          ) : (
            <span className={classes.currentBadge}>Current</span>
          )}
        </Typography>
      </div>
      {props.description.split("~").map((desc, key) => {
        return (
          <Typography
            key={key}
            variant="body1"
            color="textSecondary"
            component="p"
          >
            {desc}
          </Typography>
        );
      })}

      <LineSeparator />
    </Container>
  );
};

export default HistoricalItem;
