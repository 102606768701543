import {
  Card,
  CardMedia,
  CardActions,
  Typography,
  CardContent,
  Grid,
} from "@material-ui/core";

import ProfilePhoto from "../../assets/profile_photo.jpeg";
import SAPLogo from "../../assets/sap_logo.svg";
import GoLogo from "../../assets/go_logo.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNode, faReact, faAngular } from "@fortawesome/free-brands-svg-icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  profileImageContainer: {
    boxSizing: "borderBox",
    display: "flex",
    justifyContent: "center",
    padding: "25px",
    width: "calc(100% - 10px)",
  },
  profileImage: {
    borderRadius: "50%",
    width: "50%",
    alignSelf: "center",
    border: "1px solid #d4d8d9",
  },
  emptySpace: {
    marginBottom: "30px",
  },
});

const ProfileCard = () => {
  const classes = useStyles();
  return (
    <Card raised={true}>
      <div className={classes.profileImageContainer}>
        <CardMedia
          image={ProfilePhoto}
          title="Bayram DABAK"
          component="img"
          className={classes.profileImage}
        />
      </div>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h1">
          Bayram DABAK
        </Typography>
        <Typography variant="h6" color="textSecondary" component="h2">
          SAP HCM,ABAP and FIORI Consultant
          <br />
          İstanbul, Türkiye
        </Typography>
        <br />
        <Typography variant="body1" color="textSecondary" component="p">
          I have been working as a SAP Consultant since I graduated from
          Yeditepe University Computer Engineering department in 2005.
          <br />
          <br />
          My core area of expertise in SAP is on HCM module and its technical
          aspects. Until now, I worked as SAP HCM, ABAP and FIORI consultant in
          various SAP projects.
          <br />
          <br />
          Other than SAP, I also develop software both on the frontend and
          backend languages such as Go, NodeJS, React, Angular, Flutter to keep
          up with the new developments in technology and to use the best
          technology for special use-cases.
          <br />
          <br />
          Since 2013, I have been working in{" "}
          <a href="https://www.smod.com.tr" target="_blank" rel="noreferrer">
            SMOD Business Solutions
          </a>{" "}
          as Founding Partner and Solution Architect.
          <span className={classes.emptySpace}>&nbsp;</span>
        </Typography>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <img
            src={SAPLogo}
            alt=""
            width="38px"
            style={{ cursor: "pointer" }}
          />

          <img src={GoLogo} alt="" width="38px" style={{ cursor: "pointer" }} />

          <FontAwesomeIcon
            icon={faNode}
            size="2x"
            style={{ cursor: "pointer" }}
          />

          <FontAwesomeIcon
            icon={faReact}
            size="2x"
            style={{ cursor: "pointer" }}
          />

          <FontAwesomeIcon
            icon={faAngular}
            size="2x"
            style={{ cursor: "pointer" }}
          />
        </Grid>
      </CardActions>
    </Card>
  );
};

export default ProfileCard;
