import { Fragment } from "react";
import WorkExperiences from "./WorkExperiences";

const ProfileDetail = () => {
  return (
    <Fragment>
      <WorkExperiences />
    </Fragment>
  );
};

export default ProfileDetail;
